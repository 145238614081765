import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import WebFont from 'webfontloader';
import './dist/css/ers-icon.css';
import 'animate.css';
import './index.css';
import * as serviceWorker from './serviceWorker';


WebFont.load({
    google: {
        families: ['Poppins:400,600', 'sans-serif']
    }
});

ReactDOM.render( < App / > , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();