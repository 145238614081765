import React, { Component } from 'react';
import $ from 'jquery';
import '../dist/scss/footer.scss';
class Footer extends Component {
    allPartners() {
        $(this.refs['all-partners']).slideToggle().parent('li').toggleClass('show');
    }
    othPartners() {
        $(this.refs['oth-partners']).slideToggle().parent('li').toggleClass('show');
    }
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="about-us column">
                                <h4>About Us</h4>
                                <ul>
                                    <li><a target="_blank" href="//www.bdjobs.com/about.asp" rel="noopener noreferrer">About Bdjobs.com</a></li>
                                    <li><a target="_blank" href="//www.bdjobs.com/tos.asp" rel="noopener noreferrer">Terms &amp; Conditions</a></li>
                                    <li className="sub"><button onClick={this.allPartners.bind(this)}>International Partners</button>
                                        <ul className="all-partners" ref="all-partners">
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.babajob.com/" target="_blank" rel="noopener noreferrer">BabaJob</a> (India)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.brightermonday.com/" target="_blank" rel="noopener noreferrer">Brighter Monday</a> (Africa)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.catho.com.br/" target="_blank" rel="noopener noreferrer">Catho</a> (Brazil)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.jobberman.com/" target="_blank" rel="noopener noreferrer">Jobberman</a> (West Africa)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.jobsdb.com/" target="_blank" rel="noopener noreferrer">jobsDB</a> (S.E Asia)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.jobstreet.com/" target="_blank" rel="noopener noreferrer">JobStreet</a> (S.E Asia)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="https://www.jora.com/" target="_blank" rel="noopener noreferrer">Jora</a> (Worldwide)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.manager.com.br/" target="_blank" rel="noopener noreferrer">Manager</a> (Brazil)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="https://www.occ.com.mx/" target="_blank" rel="noopener noreferrer">OCC Mundial </a> (Mexico)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.seek.com.au/" target="_blank" rel="noopener noreferrer">SEEK</a> (Australia)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="https://www.workana.com" target="_blank" rel="noopener noreferrer">Workana</a> (Latin America)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.zhaopin.com/" target="_blank" rel="noopener noreferrer">Zhaopin</a> (China)</li>
                                        </ul>
                                    </li>
                                    <li className="sub"><button onClick={this.othPartners.bind(this)}>Other Partners</button>
                                        <ul className="oth-partners" ref="oth-partners">
                                            <li><i className="icon-list-angle-right"></i><a href="https://ajkerdeal.com/" target="_blank" rel="noopener noreferrer">AjkerDeal</a> (Bangladesh)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="https://www.beshto.com/" target="_blank" rel="noopener noreferrer">Beshto</a>  (Bangladesh)</li>
                                            <li><i className="icon-list-angle-right"></i><a href="http://www.aspiringminds.com/" target="_blank" rel="noopener noreferrer">Aspiring Minds</a> (India)</li>
                                        </ul>
                                    </li>
                                    <li><a target="_blank" href="http://www.bdjobs.com/policy/Privacy_policy.asp" rel="noopener noreferrer">Privacy  Policy</a></li>
                                    <li><a target="_blank" href="http://www.jobs.bdjobs.com/feedback.asp" rel="noopener noreferrer">Feedback</a></li>
                                    <li><a target="_blank" href="http://www.bdjobs.com/contact.asp" rel="noopener noreferrer">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="job-seekers column">
                                <h4>Job Seekers</h4>
                                <ul>
                                    <li><a target="_blank" href="http://www.mybdjobs.bdjobs.com/createaccount.asp" rel="noopener noreferrer">Create Account</a></li>
                                    <li><a target="_blank" href="http://www.bdjobs.com/career/counsel.asp" rel="noopener noreferrer">Career Counseling</a></li>
                                    <li><a target="_blank" href="http://www.mybdjobs.bdjobs.com/" rel="noopener noreferrer">My Bdjobs</a></li>
                                    <li><a target="_blank" href="http://www.mybdjobs.bdjobs.com/mybdjobs/faq.asp#1" rel="noopener noreferrer">FAQ</a></li>
                                    <li><a target="_blank" href="http://www.mybdjobs.bdjobs.com/mybdjobs/videoHelp.asp" rel="noopener noreferrer">Video Guides</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="employers column">
                                <h4>Employers</h4>
                                <ul>
                                    <li><a target="_blank" href="http://www.corporate3.bdjobs.com/Corporate_NewAccount.asp" rel="noopener noreferrer">Create Account</a></li>
                                    <li><a target="_blank" href="http://www.corporate3.bdjobs.com/services.asp" rel="noopener noreferrer">Products/Service</a></li>
                                    <li><a target="_blank" href="http://www.corporate3.bdjobs.com/" rel="noopener noreferrer">Post a Job</a></li>
                                    <li><a target="_blank" href="http://www.corporate3.bdjobs.com/help/help.asp" rel="noopener noreferrer">FAQ</a></li>
                                </ul>
                                <div className="app-badge">
                                    <h6>Download Employer App</h6>
                                    <ul>
                                        <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.bdjobs.recruiter" rel="noopener noreferrer"><img className="img-fluid" src={require('../dist/images/google-play-badge.svg')} alt="google play badge" /> </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="social column">
                                <h4>Tools & Social Media</h4>
                                <ul>
                                    <li><img className="img-fluid" src={require("../dist/images/bdjobs-android-app-logo.svg")} alt="bdjobs android app logo" /><a target="_blank" href="//www.bdjobs.com/apps/" rel="noopener noreferrer">Bdjobs Android App</a></li>
                                    <li><img className="img-fluid" src={require("../dist/images/bdjobs-android-app-logo.svg")} alt="bdjobs ios app logo" /><a target="_blank" href="//www.bdjobs.com/apps/ios/index.html" rel="noopener noreferrer">Bdjobs iOS App</a></li>
                                    <li><i className="icon-facebook"></i><a target="_blank" href="https://www.facebook.com/mybdjobs" rel="noopener noreferrer">Facebook</a></li>
                                    <li><i className="icon-google"></i><a target="_blank" href="https://bit.ly/bdjobs-google-post" rel="noopener noreferrer">Google</a></li>
                                    <li><i className="icon-youtube"></i><a target="_blank" href="https://www.youtube.com/mybdjobs" rel="noopener noreferrer">Youtube</a></li>
                                    <li><i className="icon-linkedin-in-brands"></i><a target="_blank" href="https://linkedin.com/company/mybdjobs" rel="noopener noreferrer">LinkedIn</a></li>
                                </ul>
                                <div className="app-badge">
                                    <h6>Download Mobile App</h6>
                                    <ul>
                                        <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.bdjobs.recruiter" rel="noopener noreferrer"><img className="img-fluid" src={require('../dist/images/google-play-badge.svg')} alt="google play badg" /> </a></li>
                                        <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.bdjobs.recruiter" rel="noopener noreferrer"><img className="img-fluid" src={require('../dist/images/bdjobs-app-old-badge.svg')} alt="bdjobs app old badge" /> </a></li>
                                        <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.bdjobs.recruiter" rel="noopener noreferrer"><img className="img-fluid" src={require('../dist/images/app-store-badge.svg')} alt="app store badge" /> </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="support">Need any support ? Call to <i className="icon-phone-alt"></i> <a href="tel:16479">16479</a></h4>
                        </div>
                    </div>
                </div>
            </footer >
        )
    }
}
export default Footer


