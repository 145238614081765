import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-scroll';
import '../dist/scss/header.scss';

class Header extends Component {
    state = {};
    handleScroll = this.handleScroll.bind(this);
    handleScroll() {
        this.setState({ scroll: window.scrollY - 0.02 });
    }
    componentDidMount() {
        const el = document.querySelector('.header-top-bar');
        const navEl = document.querySelector('nav');
        this.setState({
            height: el.offsetHeight,
            navHeight: navEl.offsetHeight,
        });
        window.addEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate() {
        this.state.scroll > this.state.height ?
            document.body.style.paddingTop = `${this.state.navHeight}px` :
            document.body.style.paddingTop = 0;
    }
    showMobileMenu() {
        $(this.refs['collapse']).addClass('show');
    }
    closeMobileMenu() {
        $(this.refs['collapse']).removeClass('show');
    }
    render() {
        return (
            <header>
                <div className="header-top-bar d-none d-md-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="menu-social">
                                    <ul className="top-bar-menu">
                                        <li>
                                            <a href="https://jobs.bdjobs.com/jobsearch.asp" className="jobs active">Jobs</a>
                                        </li>
                                        <li>
                                            <a href="https://mybdjobs.bdjobs.com/mybdjobs/signin.asp" className="my-bdjobs">My Bdjobs</a>
                                        </li>
                                        <li>
                                            <a href="https://bdjobstraining.com/" className="training">Training</a>
                                        </li>
                                        <li>
                                            <a href="https://corporate3.bdjobs.com/" className="employers">Employers</a>
                                        </li>
                                    </ul>
                                    <div className="social-lang">
                                        <ul className="social">
                                            <li>
                                                <a href="https://www.facebook.com/mybdjobs" className="facebook"><i className="icon-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://bit.ly/bdjobs-google-post" className="google"><i className="icon-google"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/mybdjobs" className="youtube"><i className="icon-youtube"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.bdjobs.com/contact.asp" className="phone"><i className="icon-phone-alt"></i></a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className={this.state.scroll >= this.state.height ? "navbar navbar-expand-lg navbar-light bg-light sticky fadeInDown animated" : "navbar navbar-expand-lg navbar-light bg-light"}>
                    <div className="container">
                        <a className="navbar-brand" href="http://ers.bdjobs.com/">
                            <img className="img-fluid" src={require('../dist/images/logo.png')} alt="logo" />
                        </a>
                        <button className="navbar-toggler" type="button" onClick={this.showMobileMenu.bind(this)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent" ref="collapse">
                            <i className="icon-times d-lg-none" onClick={this.closeMobileMenu.bind(this)}></i>
                            <div className="loged-in order-md-2">
                               { /*<div className="dropdown">
                                    <i className="icon-user"></i><button className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        User Name</button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="http://ers.bdjobs.com/">Action</a>
                                        <a className="dropdown-item" href="http://ers.bdjobs.com/">Another action</a>
                                        <a className="dropdown-item" href="http://ers.bdjobs.com/">Something else here</a>
                                    </div>
                                </div>*/}
                            </div>
                            <ul className="navbar-nav mr-auto order-md-1">
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="whats-ERS" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>What's ERS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="features" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>Features</Link>
                                </li>
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="benefits" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>Benefits</Link>

                                </li>
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="where-we-worked" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>Where We Worked</Link>

                                </li>
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="our-clients" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>Our Clients</Link>

                                </li>
                                <li className="nav-item">
                                    <Link href="#" activeClass="active" className="nav-link" to="contact-us" spy={true} smooth={true} duration={500} offset={-this.state.navHeight}>Contact Us</Link>

                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}
export default Header;