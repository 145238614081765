import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css';
import './dist/scss/home-page.scss';
import Header from './header/Header';
import Footer from './footer/Footer';



export default class HomePage extends Component {
    render() {
        const our_clients_params = {
            slidesPerView: 6,
            slidesPerColumn: 2,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                1200: {
                    slidesPerView: 5,
                },
                991: {
                    slidesPerView: 4,
                },
                767: {
                    slidesPerView: 3,
                    navigation: false,
                },
                576: {
                    slidesPerView: 2,
                }
            }
        };
        const clients_say_params = {
            slidesPerView: 2,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                767: {
                    slidesPerView: 1,
                }
            }
        };
        return (
            <div className="ers-home">
                <Header />
                <section className="banner" name="whats-ERS">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 banner-content-wrap">
                                <div className="banner-content">
                                    <h2 className="title">eRecruitment System</h2>
                                    <p>“e-Recruitment System" is an online recruitment solution developed by Bdjobs.com Ltd. The system has been implemented in some of the most reputed and largest organizations in the country. It is a fully customized application by which a corporate human resource department can significantly automate its recruitment process. </p>
                                    <p>The system is designed to suit the needs of the HR department in fulfilling the online recruitment procedure and to fit the corporate image. "e-Recruitment System’ is built on the proven expertise of the company in recruiting business domain and web technology.</p>
                                    {/*<button className="btn action-btn">See more</button>*/}
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block image-wrap">
                                <img className="img-fluid" src={require('./dist/images/banner-image.png')} alt="banner" />
                                {/*<button className="play-btn"><i className="icon-play"></i></button>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features pt-pb" name="features">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h2 className="heading">Features of the Product</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-file blue"></i>
                                    <h5 className="name">Application Form</h5>
                                    <p className="details">Customized CV/Resume form for collecting candidate's information</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-cog blush"></i>
                                    <h5 className="name">Customized Interface</h5>
                                    <p className="details">Responsive web-based application interface with customized template for
                                    recruitment administrative use </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-file peel"></i>
                                    <h5 className="name">Instant Vacancy</h5>
                                    <p className="details">Instant vacancy announcement in faster, easier and less costly process
                                </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-file panache"></i>
                                    <h5 className="name">Search Engine</h5>
                                    <p className="details">Tailor made search engine for making quick filter over the candidates’
                                    pool</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-cog porcelain"></i>
                                    <h5 className="name">Admin Panel</h5>
                                    <p className="details">Access control mechanism for login to the administrative panel</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature">
                                    <i className="icon-file wisp-pink"></i>
                                    <h5 className="name">Recruitment Test</h5>
                                    <p className="details">Necessary resource and orderly documentation before Recruitment Test</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                               {/* <button className="btn action-btn">See more</button>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="benefits pt-pb" name="benefits">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="heading">Benefits of Our Services</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="benefit">
                                            <li>
                                                <i className="icon-elevation-decline"></i>
                                                <p>Decreases overstaffing through automated recruitment</p>
                                            </li>
                                            <li>
                                                <i className="icon-chart-down"></i>
                                                <p>Reduces Cost in Recruitment Process</p>
                                            </li>
                                            <li>
                                                <i className="icon-file-user"></i>
                                                <p>Ensures a better quality in recruitment process and CVs accumulated	</p>
                                            </li>
                                            <li>
                                                <i className="icon-bars"></i>
                                                <p>Simplifes recruitment process in more efficient, transparent and convenient way	</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="benefit">
                                            <li>
                                                <i className="icon-support"></i>
                                                <p>Dedicates support for the job seekers while a system is live</p>
                                            </li>
                                            <li>
                                                <i className="icon-graph"></i>
                                                <p>Enhances corporate brand of the company in the job Market</p>
                                            </li>
                                            <li>
                                                <i className="icon-file-cog"></i>
                                                <p>Maintains uniformity for CVs submitted into the system	</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 benefit-img">
                                <img className="img-fluid" src={require("./dist/images/benefits_of_our_services.png")} alt="benefits of our services" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fun-facts">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 management-wrap fact">
                                <i className="icon-slope-downhill"></i>
                                <div className="management">
                                    <h2>85%</h2>
                                    <p>Reduced overhead<br /> in HR Management</p>
                                </div>
                            </div>
                            <div className="col-md-4 recruitment-wrap fact">
                                <i className="icon-stopwatch"></i>
                                <div className="recruitment">
                                    <h2>80%</h2>
                                    <p>Less Time in<br /> Recruitment Process</p>
                                </div>
                            </div>
                            <div className="col-md-4 satisfaction-wrap fact">
                                <i className="icon-smile"></i>
                                <div className="satisfaction">
                                    <h2>90%</h2>
                                    <p>Average<br /> Client Satisfaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="we-serving pt-pb" name="where-we-worked">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h2 className="heading">We Serving</h2>
                                        <p className="sub-heading">Serving Numerous Govt., Private and Multinational Companies in
                                        different Sectors:</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service bank">
                                            <i className="icon-bank"></i>
                                            <h5 className="name">Banking</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service telecom">
                                            <i className="icon-access-point-network"></i>
                                            <h5 className="name">Telecom</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service ngo">
                                            <i className="icon-domain"></i>
                                            <h5 className="name">NGO</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service food">
                                            <i className="icon-food"></i>
                                            <h5 className="name">Food & Beverage</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service textile">
                                            <i className="icon-tshirt-crew"></i>
                                            <h5 className="name">Textile</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="service health">
                                            <i className="icon-heart-pulse"></i>
                                            <h5 className="name">Health</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="our-clients pt-pb" name="our-clients">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h2 className="heading">Our Clients</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <Swiper {...our_clients_params}>
                                    <div><img className="img-fluid" src={require('./dist/images/arirs.png')} alt="arirs" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/bdjobs-logo.png')} alt="bdjobs logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/BOESL.png')} alt="BOESL" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/IOM_logo.png')} alt="IOM logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/mask_group.png')} alt="mask group" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/arirs.png')} alt="arirs" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/bdjobs-logo.png')} alt="bdjobs logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/BOESL.png')} alt="BOESL" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/IOM_logo.png')} alt="IOM logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/mask_group.png')} alt="mask group" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/arirs.png')} alt="arirs" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/bdjobs-logo.png')} alt="bdjobs logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/BOESL.png')} alt="BOESL" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/IOM_logo.png')} alt="IOM logo" /></div>
                                    <div><img className="img-fluid" src={require('./dist/images/mask_group.png')} alt="mask group" /></div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
               
                <section className="contact" name="contact-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 d-flex align-items-center address-wrap">
                                <div className="address">
                                    <i className="icon-user-headset d-none d-md-block"></i>
                                    <h4 className="call">Call <a href="tel:16479">16479</a></h4>
                                    <p className="company">Bdjobs.com Limited <br />
                                        8th Floor - West BDBL Building (Old BSRS) <br />
                                        12, Kawran Bazar C/A <br />
                                        Dhaka-1215, Bangladesh </p>
                                </div>
                            </div>
                            <div className="col-md-7 d-flex align-items-center">
                                <div className="contact-form">
                                    <h5>Fill this form to learn more</h5>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder=" " required />
                                                <label>Name *</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder=" " />
                                                <label>Company Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder=" " required />
                                                <label>Phone *</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder=" " required />
                                                <label>Email *</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea className="form-control" rows="5" placeholder=" "></textarea>
                                                <label>Message</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button className="btn action-btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </section >
                <section className="location-map">
                    <div className="row">
                        <div className="col-md-12 map">
                            <iframe title="bdjobs.com map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.898397843808!2d90.39090371536268!3d23.751002294669465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8a2adc9622f%3A0x308a42a16fb2beb6!2sBdjobs.com+Ltd.!5e0!3m2!1sen!2sbd!4v1562227039145!5m2!1sen!2sbd" width="100%" height="250" frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        )
    }
}

