import React from 'react';
import './App.scss';
import HomePage from './HomePage';

function App() {
  return (
    <div>
      <HomePage/>
    </div>
  );
}

export default App;
